type Func<T extends any[], R> = (...args: T) => R;
export function throttleFunctionByArgs<T extends any[], R>(
	func: Func<T, R>,
	wait: number
): Func<T, void> {
	const cache = new Map<string, number>();

	return (...args: T) => {
		const key = JSON.stringify(args);
		const now = Date.now();

		if (!cache.has(key) || now - (cache.get(key) || 0) > wait) {
			cache.set(key, now);
			func(...args);
		}
	};
}

export function throttleAsyncFunctionByArgs<T extends any[], R>(
	func: Func<T, Promise<R>>,
	wait: number
): Func<T, Promise<void>> {
	const cache = new Map<string, number>();

	return async (...args: T) => {
		const key = JSON.stringify(args);
		const now = Date.now();

		if (!cache.has(key) || now - (cache.get(key) || 0) > wait) {
			cache.set(key, now);
			await func(...args);
		}
	};
}
